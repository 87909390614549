import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 41 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "clip-rule": "evenodd",
      d: "m19.737 25.384 8.47-8.47a.5.5 0 0 0 0-.707l-1.414-1.414a.5.5 0 0 0-.707 0L18.5 22.379l-3.586-3.586a.5.5 0 0 0-.707 0l-1.414 1.414a.499.499 0 0 0 0 .707l4.469 4.47c.341.341.789.512 1.238.512.448 0 .896-.171 1.237-.512M20.5 5c-8.271 0-15 6.729-15 15s6.729 15 15 15 15-6.729 15-15-6.729-15-15-15m0 33c-9.926 0-18-8.074-18-18 0-9.925 8.074-18 18-18 9.925 0 18 8.075 18 18 0 9.926-8.075 18-18 18"
    }, null, -1)
  ])))
}
export default { render: render }